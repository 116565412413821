const about = `
1     /** 
2     * About me
3     * Results-driven and skilled Web/Mobile
4     * Flutter Developer with experience in 
5     * creating engaging and innovative 
6     * cross-platform mobile and web 
7     * applications.
8     * Adept at designing and implementing 
9     * user-friendly interfaces, optimizing
10    * performance, and solving complex 
11    * technical challenges. Seeking
12    * opportunities to contribute 
13    * expertise and passion for Flutter
14    * development to a dynamic team.
15    */
`;
const interest = `
1     /**
2     * Interest
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */`;
const skills = `
1     /**
2     * Skills
3     * My skills range from
4     * Problem solving and algorithms
5     * to app development using flutter 
6     * and web development using react : 
7     * Programming Languages: Dart, 
8     * TypeScript, HTML, CSS, C#, C, PHP.
9     * Flutter Framework
10    * State Management (Provider, BLoC)
11    * Firebase (Authentication, Firestore
12    * , Cloud Functions)
13    * RESTful APIs
14    * Version Control (Git/GitHub)
15    * Problem Solving and Debugging
16    * Excellent Communication Skills
17    */
`;
const experience = `
1     /**
2     * Experience
3     * Membre Multimédia dans le club CSE
4     * novembre 2021-2022
5     * Membre Game development dans 
6     * Micro Club décembre 2021-Today
7     * Membre Marketing Micro Club avril
8     * 2022-Today
9     * Membre It-App development Micro Club
10    * Mai 2022-Today
11    */
`;
const education = `
1     /**
2     * Education
3     * - Baccalauréat 2020-2021
4     * - Mathématiques et informatique 
5     * USTHB 2021-2022.
6     * Relevant Coursework: Algorithms
7     * and problem solving
8     * - Licence : Ingénierie des systèmes
9     * informatiques et logiciels 
10    * USTHB 2022-2024
11    * Relevant Coursework: Database, 
12    * Software and Conceptual engineering
13     */
`;
const certificates = `
1     /**
2     * Certificates
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */
`;
const hobbies = `
1     /**
2      * Hobbies
3      * When I'm not immersed in the world
4      * of software engineering, I indulge 
5      * in a variety of hobbies that
6      * bring balance to my life. One of 
7      * my greatest passions is cars; I am
8      * fascinated by their design,
9      * performance, and the technology that
10     * goes into making them not just a mode 
11     * of transportation but works of art.
12     * I also enjoy the immersive world of 
13     * video games, where I can explore
14     * new virtual landscapes, solve 
15     * puzzles, and compete with friends.
16     * Programming isn't just my profession;
17     * it's also a hobby. I love diving 
18     * into new coding challenges, 
19     * experimenting with different 
20     * technologies, and building personal 
21     * projects that allow me to express
22     *  my creativity. 
23     * These hobbies provide me with a 
24     * well-rounded perspective and 
25     * inspire my approach to software 
26     * development.
27     */
`;
export {experience , skills , certificates , interest, education, hobbies, about};